'use client';

import { ReactNode, createContext, useEffect, useState, useCallback, Suspense } from 'react';
import { globalDiscount } from '@/constants/fragments';
import { getAuth, signInWithCustomToken, signOut, User } from 'firebase/auth';
import { useRouter } from 'next/navigation';
import signIn from '@/components/auth/actions/signIn';
import register from '@/components/auth/actions/register';
import get from '@/lib/sanity/get';
import useNotification from '@/hooks/useNotification';
import useFirebase from '@/hooks/useFirebase';
import AuthDialog from '@/components/auth/AuthDialog';

interface AuthProviderProps {
  children: ReactNode;
}

export interface AuthContextProps {
  status: 'loading' | 'complete' | 'error';
  userData: any | null;
  userId: string | null;
  handleLogout: () => Promise<void>;
  handleLogin: ({ username, password }: { username: string; password: string }) => Promise<void>;
  handleRegister: ({
    username,
    email,
    password,
  }: {
    username: string;
    email: string;
    password: string;
  }) => Promise<void>;
  getUserData: () => Promise<any>;
  setUserData: (data: any) => void;
  openLogin: boolean;
  setOpenLogin: (open: boolean) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  status: 'loading',
  userData: null,
  handleLogout: () => Promise.resolve(),
  handleLogin: () => Promise.resolve(),
  handleRegister: () => Promise.resolve(),
  getUserData: () => Promise.resolve(null),
  setUserData: () => {},
  userId: null,
  openLogin: false,
  setOpenLogin: () => {},
});

export default function AuthProvider({ children }: AuthProviderProps) {
  const app = useFirebase();
  const auth = getAuth(app) || undefined;
  const [userData, setUserData] = useState<any>(null);
  const [status, setStatus] = useState<'loading' | 'complete' | 'error'>('loading');
  const router = useRouter();
  const { showNotification } = useNotification();
  const [openLogin, setOpenLogin] = useState(false);

  const getUserData = useCallback(() => {
    const userId = auth?.currentUser?.uid;

    if (!userId) return Promise.resolve(null);

    return get({
      query: `*[_type == "user" && _id == $userId][0] {
        "userId": _id, 
        username,
        "isTrade": coalesce(isTrade, false),
        email,
        phone,
        firstName,
        lastName,
        "globalDiscount": ${globalDiscount},
      }`,
      params: { userId },
      cache: false,
    }).then(setUserData);
  }, [auth]);

  const handleLogout = useCallback(async () => {
    return signOut(auth)
      .then(() =>
        showNotification({
          severity: 'info',
          message: "You've been logged out.",
        }),
      )
      .catch(() =>
        showNotification({
          severity: 'error',
          message: 'There was an error logging you out.',
        }),
      );
  }, [auth, showNotification]);

  const handleLogin = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      if (!auth) return;

      if (!username || !password)
        return showNotification({
          severity: 'error',
          message: 'Please enter a valid username and password.',
        });

      const { error, customToken } = await signIn({ username, password });

      if (error || !customToken) {
        return showNotification({
          severity: 'error',
          message: `There was an error logging you in: ${error}`,
        });
      }

      return signInWithCustomToken(auth, customToken)
        .then(() => {
          showNotification({
            severity: 'success',
            message: 'You have been successfully logged in.',
          });
        })
        .catch((error) => {
          showNotification({
            severity: 'error',
            message: `There was an error logging you in: ${error.message}`,
          });
        });
    },
    [auth, showNotification],
  );

  const handleRegister = useCallback(
    async ({
      username,
      email,
      password,
    }: {
      username: string;
      email: string;
      password: string;
    }) => {
      if (!auth) return;

      if (!username || !email || !password)
        return showNotification({
          severity: 'error',
          message: 'Please enter a valid username, email, and password.',
        });

      const { error, customToken } = await register({
        username,
        email,
        password,
      });

      if (error || !customToken) {
        return showNotification({
          severity: 'error',
          message: `There was an error registering: ${error}`,
        });
      }

      return signInWithCustomToken(auth, customToken)
        .then(() => {
          showNotification({
            severity: 'success',
            message: 'You have been successfully registered and logged in.',
          });
        })
        .catch((error) => {
          showNotification({
            severity: 'error',
            message: `There was an error logging you in after registration: ${error.message}`,
          });
        });
    },
    [auth, showNotification],
  );

  const handleAuthStateChange = useCallback(
    async (user: User | null) => {
      if (!user) {
        document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        setUserData(null);
      } else {
        const token = await user.getIdToken();
        document.cookie = `token=${token}; path=/;`;
        await getUserData();
      }

      router.refresh();
      setStatus('complete');
    },
    [getUserData, router],
  );

  useEffect(() => {
    if (!auth) return;

    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);

    return () => unsubscribe();
  }, [auth, handleAuthStateChange]);

  const contextValue: AuthContextProps = {
    status,
    userData,
    userId: userData?.userId,
    getUserData,
    setUserData,
    handleLogin,
    handleRegister,
    handleLogout,
    openLogin,
    setOpenLogin,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      <Suspense fallback={<div />}>
        <AuthDialog />
      </Suspense>

      {children}
    </AuthContext.Provider>
  );
}
