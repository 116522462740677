'use client';

import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'next/navigation';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Login from '@/components/auth/Login';
import ResetPassword from '@/components/auth/ResetPassword';
import useAuth from '@/hooks/useAuth';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Image from '@/components/Image';
import get from '@/lib/sanity/get';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SignUp from '@/components/auth/SignUp';

const RESET_PASSWORD = 'reset-password';
const LOGIN = 'login';
const SIGN_UP = 'sign-up';

export default function AuthDialog() {
  const [type, setType] = useState(LOGIN);
  const [image, setImage] = useState<string | null>(null);
  const { userId, openLogin, setOpenLogin } = useAuth();
  const searchParams = useSearchParams();

  useEffect(() => {
    const login = searchParams.get('login');

    if (login) {
      setOpenLogin(true);
    }
  }, [searchParams, setOpenLogin]);

  const handleClose = useCallback(() => {
    setOpenLogin(false);
    setType(LOGIN);
  }, [setOpenLogin, setType]);

  const handleComplete = useCallback(() => {
    setType(LOGIN);
  }, [setType]);

  useEffect(() => {
    get({
      query: `*[_id == "generalSettings"][0].login.image.asset._ref`,
      cache: true,
      tags: ['generalSettings'],
    }).then(setImage);
  }, []);

  useEffect(() => {
    if (!!userId) handleClose();
  }, [userId, handleClose]);

  if (!!userId) return null;

  return (
    <Dialog open={openLogin} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogContent sx={{ p: 0 }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
        >
          <CloseIcon />
        </IconButton>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Box
            sx={{
              flex: 1,
              aspectRatio: {
                xs: '3/2',
                md: '4/5',
              },
              width: '100%',
            }}
          >
            {image && (
              <Image
                src={image as string}
                alt='login'
                aspectRatio={{
                  xs: '3/2',
                  md: '4/5',
                }}
                sx={{ width: '100%' }}
                sizes='600px'
                objectFit='cover'
              />
            )}
          </Box>

          <Stack
            direction='column'
            spacing={1}
            sx={{
              flex: 1,
              px: 2,
              py: 3,
              justifyContent: 'center',
            }}
          >
            <div>
              <Typography variant='h5' gutterBottom className='title-underlined'>
                {
                  {
                    [LOGIN]: 'Login',
                    [RESET_PASSWORD]: 'Reset Password',
                    [SIGN_UP]: 'Create account',
                  }[type]
                }
              </Typography>
            </div>

            {type === LOGIN && <Login />}
            {type === SIGN_UP && <SignUp onComplete={handleComplete} />}
            {type === RESET_PASSWORD && (
              <ResetPassword onComplete={handleComplete} initialEmail='' />
            )}

            <Stack
              direction='row'
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                '& a': {
                  cursor: 'pointer',
                  color: 'text.primary',
                  fontWeight: 'bold',
                  ml: 0.75,
                },
              }}
            >
              {LOGIN === type && (
                <>
                  <Typography variant='body2' color='text.secondary'>
                    Don&apos;t have an account?
                    <Link onClick={() => setType(SIGN_UP)} variant='body2'>
                      Sign Up
                    </Link>
                  </Typography>

                  <Link onClick={() => setType(RESET_PASSWORD)} variant='body2'>
                    Forgot Password?
                  </Link>
                </>
              )}

              {[RESET_PASSWORD, SIGN_UP].includes(type) && (
                <Typography variant='body2' color='text.secondary'>
                  Go back to the
                  <Link
                    onClick={() => setType(LOGIN)}
                    variant='body2'
                    color='text.primary'
                    sx={{
                      fontWeight: 'bold',
                      ml: 0.5,
                    }}
                  >
                    Login
                  </Link>
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
