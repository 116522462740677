import { useContext } from 'react';
import { FirebaseContext } from '@/components/contexts/FirebaseProvider';

const useFirebaseApp = () => {
  const firebaseApp = useContext(FirebaseContext);
  if (!firebaseApp) {
    throw new Error(
      'Cannot call useFirebaseApp unless your component is within a FirebaseAppProvider',
    );
  }

  return firebaseApp;
};

export default useFirebaseApp;
