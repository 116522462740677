import { useContext } from 'react';
import { NotificationContext } from '@/components/contexts/NotificationProvider';

const useNotification = () => {
  const methods = useContext(NotificationContext);

  return methods;
};

export default useNotification;
