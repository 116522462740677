import MuiSnackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

interface SnackbarData {
  type?: 'snackbar' | 'dialog';
  open?: boolean;
  message?: string;
  severity?: AlertColor;
  mode?: 'loading' | 'normal';
}

export default function Snackbar({
  data,
  handleClose,
}: {
  data: SnackbarData;
  handleClose: () => void;
}) {
  if (data?.type !== 'snackbar') return null;

  return (
    <MuiSnackbar
      open={data.open ?? false}
      autoHideDuration={6000}
      onClose={handleClose}
      key={data.message}
      slots={{
        transition: Slide,
      }}
    >
      <Alert
        severity={data.severity ?? 'info'}
        sx={{ width: '100%' }}
        variant='standard'
        onClose={data.mode === 'loading' ? undefined : handleClose}
        icon={data.mode === 'loading' ? <CircularProgress size={20} color='inherit' /> : undefined}
      >
        {data.message ?? 'No message provided'}
      </Alert>
    </MuiSnackbar>
  );
}
