'use client';

import React, { useState, useCallback, useTransition } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import useAuth from '@/hooks/useAuth';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPending, startTransition] = useTransition();
  const { handleLogin } = useAuth();

  const handleLoginButton = useCallback(
    () =>
      startTransition(async () => {
        return handleLogin({ username, password });
      }),
    [username, password, handleLogin],
  );

  return (
    <Stack direction='column' spacing={2} component='form' sx={{ py: 2 }}>
      <TextField
        label='Email or Username'
        value={username}
        color='primary'
        onChange={(e) => setUsername(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleLoginButton();
          }
        }}
        placeholder='Enter email or username'
        type='string'
        size='small'
        fullWidth
        autoComplete='email'
        disabled={isPending}
      />

      <TextField
        label='Password'
        value={password}
        color='primary'
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Enter password'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleLoginButton();
          }
        }}
        type='password'
        size='small'
        fullWidth
        autoComplete='current-password'
      />

      <Button
        variant='contained'
        color='primary'
        fullWidth
        disabled={!username || !password}
        loading={isPending}
        onClick={handleLoginButton}
      >
        Login
      </Button>
    </Stack>
  );
}
