import { CUSTOMISE_SEARCH_PARAM } from './general';

export const links = `
type == 'link' => {
    href,
    text,
    "visible": true
},
type == 'none' => {
    "href": '',
    "text": '',
    "visible": false
},
type == 'reference' => {
    ...(reference -> {
        "text": coalesce(^.text, seo.title, title),
        "slug": coalesce(seo.slug.current, slug.current),
        "type": _type,
        "image": seo.image.asset -> {
            "src": _id,
            "aspectRatio": metadata.dimensions.aspectRatio,
        },
        "visible": status == "published"
    })
} {
    text,
    image,
    visible,
    "href": select(
        type == 'product' => '/products/', 
        type == 'collection' => '/collections/',
        type == 'project' => '/projects/',
        type in ['page', 'systemPage'] => '/'
    ) + slug
},
type == 'configure' => {
    ...( product -> {
        "text": coalesce(^.text, seo.title, 'Configure'),
        "visible": status == "published",
        "href": "/products/" + seo.slug.current + "?${CUSTOMISE_SEARCH_PARAM}=true",
    })
}`;

export const collectionDiscount = `...collections[] -> discount`;

export const lineItem = `quantity,
...product -> {
    "id": _id,
    "productId": product._ref,
    name,
    price,
    "defaultValues": coalesce(defaultValues.code, '{}'),
    "widgets": coalesce(product -> configurator -> widgets[], [])[] {
        "elements": coalesce(elements[], [])[] {
            id,
            label,
            "conditionals": coalesce(conditionals, []),
            "hideFromSummary": coalesce(hideFromSummary, false),
            "options": coalesce(options[], [])[] {
                value,
                label
            }
        }
    },
    sku,
    "discount": math::max([...(coalesce(product -> collections[] -> discount, []))]),
    "image": image.asset._ref,
    "custom": coalesce(custom, false)
}`;

export const globalDiscount = `select(
    isTrade == true => team -> plan -> discount,
    isTrade == false => 0
)`;

export const userDiscount = `*[_type == "user" && _id == $authId][0] { 
    "value": ${globalDiscount}
}.value`;

export const productDiscount = `math::max([
    ${userDiscount},
    ${collectionDiscount}
])`;

export const productPrice = `coalesce(variations[0].price, 0)`;

export const quote = `*[_type == "quote" && user._ref == $authId]{
    "id": _id,
    "createdAt": _createdAt,
    "quantity": coalesce(math::sum(lineItems[].quantity), 0),
    "name": coalesce(name, "Untitled")
}`;
