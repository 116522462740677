'use client';

import { ReactNode, useState, useCallback, createContext } from 'react';
import Snackbar from './Snackbar';
import Dialog from './Dialog';

interface Notification {
  open?: boolean;
  title?: string;
  message?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  mode?: 'loading' | 'normal';
  type?: 'snackbar' | 'dialog';
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

interface NotificationContextProps {
  showNotification: (props: Notification) => void;
  handleClose: () => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  showNotification: () => {},
  handleClose: () => {},
});

interface NotificationProviderProps {
  children: ReactNode;
}

export default function NotificationProvider({ children }: NotificationProviderProps) {
  const [data, setData] = useState<Notification>({
    open: false,
    title: '',
    message: '',
    severity: 'info',
    mode: 'normal',
    type: 'snackbar',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const handleClose = useCallback(() => {
    setData((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  const showNotification = useCallback(
    ({
      open = true,
      message = '',
      title = '',
      severity = 'info',
      type = 'snackbar',
      mode = 'normal',
      cancelText = 'Cancel',
      confirmText = 'Confirm',
      onCancel = () => {},
      onConfirm = () => {},
    }: Notification) => {
      setData({
        open,
        title,
        message,
        severity,
        mode,
        type,
        cancelText,
        confirmText,
        onCancel,
        onConfirm,
      });
    },
    [],
  );

  const contextValue: NotificationContextProps = {
    showNotification,
    handleClose,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}

      <Snackbar data={data} handleClose={handleClose} />
      <Dialog data={data as any} handleClose={handleClose} />
    </NotificationContext.Provider>
  );
}
