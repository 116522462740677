import { useCallback } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DialogData {
  type: string;
  open: boolean;
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  onCancel?: () => void;
  onConfirm?: () => void;
}

export default function Dialog({
  data,
  handleClose,
}: {
  data: DialogData;
  handleClose: () => void;
}) {
  const onClose = useCallback(() => {
    handleClose();
    data.onCancel?.();
  }, [handleClose, data]);

  if (data.type !== 'dialog') return null;

  return (
    <MuiDialog
      open={data.open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' sx={{ position: 'relative' }}>
        {data.title}

        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {data.message || 'No message provided.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='inherit' variant='text'>
          {data.cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            data.onConfirm?.();
          }}
          autoFocus
          color={data.severity || 'primary'}
        >
          {data.confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
