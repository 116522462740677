import { SxProps } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import NextImage from 'next/image';

interface ImageProps {
  src: string;
  alt: string;
  aspectRatio?:
    | string
    | {
        [key: string]: string;
      };
  sx?: SxProps;
  sizes?: string;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  priority?: boolean;
  loading?: 'eager' | 'lazy';
}

export default function Image({
  src,
  alt,
  aspectRatio,
  sx,
  sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
  objectFit = 'cover',
  priority = false,
  loading = 'lazy',
}: ImageProps) {
  if (!src)
    return (
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'grey.50',
          aspectRatio,
          ...sx,
        }}
      />
    );

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        aspectRatio,
        '& img': {
          objectFit,
        },
        ...sx,
      }}
    >
      <NextImage src={src} alt={alt} fill sizes={sizes} priority={priority} loading={loading} />
    </Box>
  );
}
