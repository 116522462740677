'use client';

import { useCallback, useEffect, useState, useTransition } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useNotification from '@/hooks/useNotification';
import createOTP from './createOTP';
import verifyOTP from './verifyOTP';
import setNewPassword from './setNewPassword';

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function ResetPassword({
  onComplete,
  initialEmail,
}: {
  onComplete: () => void;
  initialEmail: string;
}) {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);
  const [isSending, startSending] = useTransition();
  const [isVerifying, startVerifying] = useTransition();
  const [isResetting, startResetting] = useTransition();
  const [OTPCode, setOTPCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { showNotification } = useNotification();

  const handleSendOTP = useCallback(
    () =>
      startSending(() => {
        if (!isValidEmail(email)) {
          showNotification({
            message: 'Invalid email format',
            severity: 'error',
          });
          return;
        }
        createOTP(email).then(() => {
          showNotification({
            message: 'OTP sent to your email',
            severity: 'success',
          });
          setStep(1);
        });
      }),
    [email, showNotification, startSending],
  );

  const handleVerifyOTP = useCallback(
    () =>
      startVerifying(() => {
        verifyOTP(email, OTPCode).then((isValid) => {
          if (isValid) {
            showNotification({
              message: 'OTP verified successfully',
              severity: 'success',
            });
            setStep(2);
          } else {
            showNotification({
              message: 'Invalid OTP',
              severity: 'error',
            });
          }
          setOTPCode('');
        });
      }),
    [email, OTPCode, showNotification, startVerifying],
  );

  const handleResetPassword = useCallback(
    () =>
      startResetting(() => {
        setNewPassword(email, password)
          .then(() => {
            setStep(0);
            setEmail('');
            setOTPCode('');
            setPassword('');
            setConfirmPassword('');
            onComplete();

            showNotification({
              message: 'Password changed successfully, please login to continue',
              severity: 'success',
            });
          })
          .catch(() => {
            showNotification({
              message: 'Error changing password',
              severity: 'error',
            });
          });
      }),
    [email, password, showNotification, startResetting, onComplete],
  );

  useEffect(() => {
    if (initialEmail) {
      setStep(1);
      setEmail(initialEmail);
      handleSendOTP();
    }
  }, [initialEmail, handleSendOTP]);

  return (
    <Stack direction='column' spacing={3} component='form' sx={{ pb: 1 }}>
      {step === 0 && (
        <>
          <Typography
            variant='body2'
            sx={{
              color: 'text.primary',
            }}
          >
            Please enter your email registered with your account, we will send you a code to reset
            your password.
          </Typography>

          <TextField
            value={email}
            color='primary'
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Email Address'
            type='email'
            size='small'
            fullWidth
            autoComplete='email'
          />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={!email}
            onClick={handleSendOTP}
            loading={isSending}
          >
            Send OTP
          </Button>
        </>
      )}

      {step === 1 && (
        <>
          <Typography
            variant='body1'
            sx={{
              color: 'text.primary',
            }}
          >
            Please enter the OTP sent to your email.
          </Typography>

          <TextField
            value={OTPCode}
            color='primary'
            onChange={(e) => setOTPCode(e.target.value)}
            placeholder='One Time Code'
            type='text'
            size='small'
            fullWidth
            autoComplete='one-time-code'
          />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={!OTPCode}
            onClick={handleVerifyOTP}
            loading={isVerifying}
          >
            Verify OTP
          </Button>
        </>
      )}

      {step === 2 && (
        <>
          <Typography
            variant='body1'
            sx={{
              color: 'text.primary',
            }}
          >
            Password must be at least 6 characters
          </Typography>

          <TextField
            value={password}
            color='primary'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='New Password'
            type='password'
            size='small'
            fullWidth
            autoComplete='new-password'
          />

          <TextField
            value={confirmPassword}
            color='primary'
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder='Confirm Password'
            type='password'
            size='small'
            fullWidth
            autoComplete='new-password'
          />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={
              !password || !confirmPassword || password !== confirmPassword || password.length < 6
            }
            onClick={handleResetPassword}
            loading={isResetting}
          >
            Reset Password
          </Button>
        </>
      )}
    </Stack>
  );
}
