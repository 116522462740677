'use client';

import React, { useCallback, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { IcreateUser } from '@/components/auth/types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import createUser from '@/components/auth/actions/createUser';
import userFields from './userFields.json';
import useNotification from '@/hooks/useNotification';
import useAuth from '@/hooks/useAuth';

interface IField {
  name: string;
  label?: string;
  placeholder?: string;
  type: string;
  autoComplete?: string;
  size?: number;
  value?: string;
  options?: { value: string; label: string }[];
  required?: boolean;
}

export default function SignUp({ onComplete = () => {} }: { onComplete?: () => void }) {
  const [isPending, startTransition] = useTransition();
  const { showNotification } = useNotification();
  const { handleLogin } = useAuth();

  const inputFields: IField[] = userFields;
  const { register, handleSubmit } = useForm();

  const handleCreateAccount = useCallback(
    async (data: Partial<IcreateUser>) => {
      startTransition(async () => {
        return createUser({ ...data, templateId: 'newUser' }).then(async ({ error, success }) => {
          if (error) {
            showNotification({
              message: error,
              severity: 'error',
            });
          }

          if (success) {
            await handleLogin({
              username: data.email as string,
              password: data.password as string,
            });

            showNotification({
              message: 'Account created successfully',
              severity: 'success',
            });

            onComplete();
          }
        });
      });
    },
    [onComplete, showNotification, startTransition, handleLogin],
  );

  return (
    <form onSubmit={handleSubmit(handleCreateAccount)}>
      <Grid container spacing={2} sx={{ py: 1.5 }}>
        {inputFields.map(
          ({ label, name, placeholder, type, autoComplete, size = 6, options, required }) => (
            <Grid
              size={{
                xs: 12,
                sm: size,
              }}
              key={name}
            >
              <TextField
                label={label}
                color='primary'
                fullWidth
                size='small'
                required={required}
                placeholder={placeholder}
                type={type}
                autoComplete={autoComplete}
                select={type === 'select'}
                disabled={isPending}
                {...register(name)}
              >
                {options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ),
        )}

        <Grid size={12}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            type='submit'
            loading={isPending}
            sx={{ mt: 2 }}
          >
            Create account
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
