'use client';

import { ReactNode, createContext, useMemo, version } from 'react';
import { getApps, initializeApp, registerVersion } from 'firebase/app';
import type { FirebaseApp } from 'firebase/app';

const shallowEq = (a: { [key: string]: any }, b: { [key: string]: any }) =>
  a === b || [...Object.keys(a), ...Object.keys(b)].every((key) => a[key] === b[key]);

interface FirebaseAppProviderProps {
  firebaseApp?: FirebaseApp | undefined;
  appName?: string;
  children: ReactNode;
}

const DEFAULT_APP_NAME = '[DEFAULT]';

export const FirebaseContext = createContext<FirebaseApp | undefined>(undefined);

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export default function FirebaseProvider({
  appName,
  children,
  firebaseApp,
}: FirebaseAppProviderProps) {
  const getFirebaseApp: FirebaseApp = useMemo(() => {
    if (firebaseApp) {
      return firebaseApp;
    }

    const existingApp = getApps().find((app: any) => app.name === (appName || DEFAULT_APP_NAME));
    if (existingApp) {
      if (firebaseConfig && shallowEq(existingApp.options, firebaseConfig)) {
        return existingApp;
      } else {
        throw new Error(
          `Does not match the options already provided to the ${
            appName || 'default'
          } firebase app instance, give this new instance a different appName.`,
        );
      }
    } else {
      if (!firebaseConfig) {
        throw new Error('No firebaseConfig provided');
      }

      const reactVersion = version || 'unknown';
      registerVersion('react', reactVersion);
      return initializeApp(firebaseConfig, appName);
    }
  }, [firebaseApp, appName]);

  return <FirebaseContext.Provider value={getFirebaseApp}>{children}</FirebaseContext.Provider>;
}
